<template>
  <div>
    <el-input
      v-model="modelValue"
      class="input-width"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      :max="max"
      :min="min"
      :show-word-limit="showWordLimit"
      :clearable="clearable"
      :show-password="showPassword"
      :disabled="disabled"
      :size="size"
      :prefix-icon="prefixIcon"
      :suffix-icon="suffixIcon"
      :rows="rows"
      :autosize="autosize"
      :autocomplete="autocomplete"
      :readonly="readonly"
      :step="step"
      :resize="resize"
      :autofocus="autofocus"
      :form="form"
      :label="label"
      :validate-event="validateEvent"
      :tabindex="tabindex"
      @blur="validateValue"
      @keyup.native="change"
    />

    <el-color-picker    @change="change"     class="color-picker" v-model="modelValue"></el-color-picker>
  </div>
</template>
<script>
import { validEmail, validURL } from '@/utils/validate';
import TBaseControl from '@/components/trina/base//TBaseControl';
export default {
  name: 'TInput',
  extends: TBaseControl,
  props: {
    defaultValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    inputType: {
      type: String,
      default: ''
    }, // email：邮箱 url：链接 number：数字 float：浮点数 money：钱 mobile： 手机 phone：座机 postcode：邮编 idcard：身份证号 ip：ip地址
    placeholder: {
      type: String,
      default: '请输入'
    },
    maxlength: {
      type: Number,
      default: null
    },
    minlength: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 2
    },
    autosize: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    step: {
      type: Number,
      default: 1
    },
    resize: {
      type: String,
      default: 'none'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    validateEvent: {
      type: Boolean,
      default: false
    },
    form: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tabindex: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modelValue: ""
    };
  },
  created() {
    this.modelValue = this.defaultValue;
  },
  methods: {
    validateValue(event) {
      if (this.inputType === 'email' && !validEmail(this.modelValue)) {
        this.$message.error('请输入正确的email');
        return;
      } else if (this.inputType === 'url' && !validURL(this.modelValue)) {
        this.$message.error('请输入正确的URL');
        return;
      }
    },
    change() {
      this.$emit('change', this.name, this.modelValue);
    }
  }
};
</script>
<style scoped>
.input-width {
  width: 120px;
  float: left;
}
.color-picker {
  margin-left: 1px;
}
</style>
