<template>
  <div>
    <!-- <el-card class="box-card" shadow="never"> -->
    <div>
      <el-form id="myForm" ref="myForm"  :model="params" :rules="rules" :label-position="labelPosition"
        :label-width="labelWidth ? labelWidth + 'px' : ''" :size="size" :hide-required-asterisk="hideRequiredAsterisk">
        <el-row :gutter="10">
          <template v-for="(item, index) in items">
            <template v-if="item.itemType === 'hidden'">
              <input :key="index * 100" v-model="params[item.prop]" date type="hidden" />
            </template>
            <template v-else>
              <el-col :key="index" :span="item.span || span">
                <el-form-item   :id="item.prop + '_item'" :ref="item.prop + '_item'" :label="item.label" :prop="item.prop"
                  v-if="item.visible == undefined ? true : item.visible">
                  <template v-if="item.itemType === 't-select'">
                    <t-select :id="item.prop" :isPopover="item.isPopover" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :placeholder="item.placeholder" :options="item.options" :multiple="item.multiple"
                      :clearable="item.clearable || true" :filterable="item.filterable || true"
                      :value-key="item.valueKey" :label-key="item.labelKey" :default-value="item.defaultValue"
                      :type="item.type" :valset-id="item.valsetId" :url="item.url" :params="item.params"
                      :fetch="item.fetch" :auto-selected="item.autoSelected" @change="tGroupChange" />
                  </template>
                  <template v-else-if="item.itemType === 't-treeselect'">
                    <t-treeselect    :SetDeptId="item.SetDeptId"  :defaultValue="item.defaultValue"    :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :size="item.size ? item.size : size" :multiple="item.multiple" :disabled="item.disabled"
                      :placeholder="item.placeholder" :options="item.options" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :type="item.type" :valset-id="item.valsetId" :url="item.url" :params="item.params"
                      :fetch="item.fetch" :auto-selected="item.autoSelected" :class-name="item.className"
                      @select="tGroupChange" clearable />
                  </template>
                  <template v-else-if="item.itemType === 't-input'">
                    <t-input :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :size="item.size ? item.size : size" :readonly="item.readonly" :disabled="item.disabled"
                      :placeholder="item.placeholder" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :minlength="item.minLength" :maxlength="item.maxlength" :clearable="item.clearable || true"
                      :type="item.type || 'text'" :min="item.min" :max="item.max" :prefix-icon="item.prefixIcon"
                      :suffix-icon="item.suffixIcon" @change="controlChange">
                      <template v-if="item.slot" :slot="item.slot">{{
                          item.slotText
                      }}</template>
                    </t-input>
                  </template>
                  <template v-else-if="item.itemType === 'select'">
                    <el-select   :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :placeholder="item.placeholder" :multiple="item.multiple" :clearable="item.clearable || true"
                      :filterable="item.filterable || true" :value-key="item.valueKey" @change="controlChange">
                      <el-option v-for="(option, idx) in item.options" :key="idx" :label="option.label"
                        :value="option.value" />
                    </el-select>
                  </template>
                  <template v-else-if="item.itemType === 't-checkbox'">
                    <t-checkbox :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :options="item.options" :value-key="item.valueKey" :label-key="item.labelKey"
                      :default-value="item.defaultValue" :type="item.type" :valset-id="item.valsetId" :url="item.url"
                      :params="item.params" :fetch="item.fetch" @change="tGroupChange" />
                  </template>
                  <template v-else-if="item.itemType === 't-radio'">
                    <t-radio :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :disabled="item.disabled" :options="item.options" :value-key="item.valueKey"
                      :label-key="item.labelKey" :default-value="item.defaultValue" :type="item.type"
                      :valset-id="item.valsetId" :url="item.url" :params="item.params" :fetch="item.fetch"
                      @change="tGroupChange" />

                      <el-tooltip
                      v-if="item.tooltip"
                      class="item"
                      effect="dark"
                      :content="item.tooltip"
                      placement="top-start"
                    >
                      <i style="margin-left:15px;" class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <template v-else-if="item.itemType === 'switch'">
                    <el-switch :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :width="item.width" :active-color="item.activeColor" :inactive-color="item.inactiveColor"
                      :active-value="item.activeValue" :inactive-value="item.inactiveValue"
                      :active-text="item.activeText" :inactive-text="item.inactiveText"
                      :active-icon-class="item.activeIconClass" :inactive-icon-class="item.inactiveIconClass">
                    </el-switch>
                  </template>
                  <template v-else-if="item.itemType === 'checkbox'">
                    <el-checkbox :ref="item.prop" v-model="params[item.prop]" />
                  </template>
                  <template v-else-if="item.itemType === 'checkboxGroup'">
                    <el-checkbox-group :ref="item.prop" v-model="params[item.prop]" @change="controlChange">
                      <el-checkbox v-for="(option, idx) in item.options" :key="idx" :label="option.value">{{
                          option.label
                      }}</el-checkbox>
                    </el-checkbox-group>
                  </template>
                  <template v-else-if="
                    item.itemType === 'radio' ||
                    item.itemType === 'radioGroup'
                  ">
                    <el-radio-group :ref="item.prop" v-model="params[item.prop]" @change="controlChange">
                      <el-radio v-for="(option, idx) in item.options" :key="idx" :label="option.value">{{ option.label
                      }}</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else-if="item.itemType === 'date'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" type="date"
                      class="width100" value-format="yyyy-MM-dd" :name="item.prop" :placeholder="item.placeholder"
                      :size="item.size ? item.size : size" :clearable="item.clearable || true" :disabled="item.disabled"
                      :readonly="item.readonly" :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'year'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" :placeholder="item.placeholder" type="year" value-format="yyyy"
                      :size="item.size ? item.size : size" :clearable="item.clearable || true" :disabled="item.disabled"
                      :readonly="item.readonly" :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'month'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" :placeholder="item.placeholder" type="month" value-format="yyyy-MM"
                      :size="item.size ? item.size : size" :clearable="item.clearable || true" :disabled="item.disabled"
                      :readonly="item.readonly" :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'week'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" :placeholder="item.placeholder" type="week" value-format="yyyy-WW"
                      :size="item.size ? item.size : size" :clearable="item.clearable || true" :disabled="item.disabled"
                      :readonly="item.readonly" :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'dates'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" :placeholder="item.placeholder" type="dates" value-format="yyyy-MM-dd"
                      :size="item.size ? item.size : size" :clearable="item.clearable || true" :disabled="item.disabled"
                      :readonly="item.readonly" :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'daterange'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" type="daterange" range-separator="-" value-format="yyyy-MM-dd"
                      :start-placeholder="item.startPlaceholder || '开始日期'"
                      :end-placeholder="item.endPlaceholder || '结束日期'" :size="item.size ? item.size : size"
                      :clearable="item.clearable || true" :disabled="item.disabled" :readonly="item.readonly"
                      :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'monthrange'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" type="monthrange" range-separator="-" value-format="yyyy-MM"
                      :start-placeholder="item.startPlaceholder || '开始月份'"
                      :end-placeholder="item.endPlaceholder || '结束月份'" :size="item.size ? item.size : size"
                      :clearable="item.clearable || true" :disabled="item.disabled" :readonly="item.readonly"
                      :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'datetime'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-"
                      :start-placeholder="item.startPlaceholder || '开始日期'"
                      :end-placeholder="item.endPlaceholder || '结束日期'" :size="item.size ? item.size : size"
                      :clearable="item.clearable || true" :disabled="item.disabled" :readonly="item.readonly"
                      :editable="item.editable" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'datetimerange'">
                    <el-date-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" type="datetimerange" range-separator="-" value-format="yyyy-MM-dd HH:mm:ss"
                      :start-placeholder="item.startPlaceholder || '开始时间'"
                      :end-placeholder="item.endPlaceholder || '结束时间'" :size="item.size ? item.size : size"
                      :clearable="item.clearable || true" :disabled="item.disabled" :readonly="item.readonly"
                      :editable="item.editable" :default-time="item.defaultTime" :style="
                        itemStyle +
                        (item.itemWidth ? `width: ${item.itemWidth}px;` : '')
                      " :picker-options="item.pickerOptions || {}" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'time'">
                    <el-time-select :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" value-format="HH:mm" :picker-options="
                        item.pickerOptions || {
                          start: '00:00',
                          step: '00:30',
                          end: '23:30'
                        }
                      " :clearable="item.clearable || true" :readonly="item.readonly" :disabled="item.disabled"
                      :placeholder="item.placeholder || '选择时间'" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'timerange'">
                    <el-time-picker :id="item.prop" :ref="item.prop" v-model="params[item.prop]" class="width100"
                      :name="item.prop" is-range range-separator="-" :placeholder="item.placeholder"
                      :clearable="item.clearable || true" :readonly="item.readonly" :disabled="item.disabled"
                      :start-placeholder="item.startPlaceholder || '开始时间'"
                      :end-placeholder="item.endPlaceholder || '结束时间'" @change="controlChange" />
                  </template>
                  <template v-else-if="item.itemType === 'number'">
                    <el-tooltip  v-if="item.tip" class="item" effect="dark" :content="item.tip" placement="top">
                      <el-input-number
                      :id="item.prop"
                      :ref="item.prop"
                      v-model="params[item.prop]"
                      class="width100"
                      :name="item.prop"
                      :min="item.min || 0"
                      :max="item.max"
                      :step="item.step"
                      :clearable="item.clearable || true"
                      :readonly="item.readonly"
                      :disabled="item.disabled"
                      @change="controlChange"
                    />
                    </el-tooltip>
                    <el-input-number
                      v-else
                      :id="item.prop"
                      :ref="item.prop"
                      v-model="params[item.prop]"
                      class="width"
                      :name="item.prop"
                      :min="item.min || 0"
                      :max="item.max"
                      :step="item.step"
                      :clearable="item.clearable || true"
                      :readonly="item.readonly"
                      :disabled="item.disabled"
                      @change="controlChange"
                    />
                  </template>


                  <template v-else-if="item.itemType === 'textarea'">
                    <el-input :id="item.prop" :ref="item.prop" v-model="params[item.prop]" titlt="item.prop"
                      class="width100" :name="item.prop" show-word-limit type="textarea" :placeholder="item.placeholder"
                      :maxlength="item.maxlength" :rows="item.rows" :clearable="item.clearable || true"
                      :readonly="item.readonly" :disabled="item.disabled" :autosize="{ minRows: 2 }"
                      @change="controlChange">

                    </el-input>
                  </template>
                  <template v-else-if="item.itemType === 'lagrulValue'">
                    <span v-if="(params[item.prop].split(';').length!==1)">
                      <p :id="item.prop" v-for="(items, index) in params[item.prop].split(';').slice(0,(params[item.prop].split(';').length-1))" :key="items"
                     class="alert">{{items}}   <span @click="delData(item.prop,index)" class="san">x</span>
                    </p>
                    </span>
                    <span v-else>添加常用语，下发任务更快哦~</span>
                    <template v-if="item.intButtomCon" style="margin-top:1vh">
                      <template v-if="item.intButtomCon.type === 'button'">

                        <el-button v-show="(item.intButtomCon.otherInt.length === 0)"
                          @click="item.intButtomCon.events.click"
                          :style="{ width: item.intButtomCon.width }">{{ item.intButtomCon.text }}</el-button>
                        <template v-for="(btn, index) in item.intButtomCon.otherInt">
                          <span v-if="Array.isArray(btn)" :key="btn.nid">
                            <template v-for="(chil, ind) in btn">
                              <el-input :key="chil.nid" :style="{ width: chil.width }" v-if="chil.itemType === 'input'"
                                v-model="params[chil.prop]" :clearable="item.clearable || true" type='text' />
<!--                               @click="()=>{params[item.prop]+=params[btn[0].prop]}" -->
                              <el-button v-else-if="chil.itemType === 'button'"
                                @click="updateParams(item.prop, btn[0].prop)" :key="(chil.nid + ind)"
                                :style="{ width: chil.width }">{{ chil.text }}
                              </el-button>
                            </template>
                          </span>
                          <el-input :key="index" v-else-if="btn instanceof Object" :clearable="item.clearable || true"
                            type='text' />
                        </template>
                      </template>
                    </template>
                  </template>



                  <template v-else-if="item.itemType === 'editor'">
                    <Tinymce :id="item.prop" :ref="item.prop" :name="item.prop" v-model="params[item.prop]"
                      :height="item.height" />
                  </template>
                  <template v-if="item.itemType === 't-upload-pic'">
                    <t-upload-pic :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.name"
                      :placeholder="item.placeholder" :list-type="item.listType" :action="item.action"
                      :accept="item.accept" :headers="item.headers" :data="item.data" :multiple="item.multiple"
                      :limit="item.limit" :auto-upload="item.autoUpload" :disabled="item.disabled"
                      :file-list="item.fileList" :on-preview="item.onPreview" :on-error="item.onError"
                      :on-progress="item.onProgress" :on-change="item.onChange" :before-remove="item.beforeRemove"
                      :on-remove="item.onRemove" :on-success="item.onSuccess" :before-upload="item.beforeUpload"
                      :http-request="item.httpRequest" :on-exceed="item.onExceed" />
                  </template>
                  <template v-else-if="item.itemType === 't-color-input'">
                    <t-color-input :id="item.prop" :ref="item.prop" v-model="params[item.prop]" :name="item.prop"
                      :placeholder="item.placeholder" :minlength="item.minLength" :maxlength="item.maxlength"
                      :clearable="item.clearable || true" :readonly="item.readonly" :disabled="item.disabled" />
                  </template>
                  <template v-else-if="item.itemType === 't-file'">
                    <div v-for="(file, i) in params[item.prop]" style="display: inline-block">
                      <div v-if="file.contextType.indexOf('image') > -1">
                        <el-image style="width: 140px; height: 140px" :src=file.fileUrl
                          :preview-src-list=[file.fileUrl] />
                      </div>
                      <div v-else-if="file.contextType.indexOf('video') > -1">
                        <video style="width: 140px; height: 140px" :src=file.fileUrl controls="controls" />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.itemType === 'input'">
                    <el-input  style="z-index:9;"  :id="item.prop"  ref="input" v-model="params[item.prop]" :name="item.prop"
                      :placeholder="item.placeholder" :minlength="item.minLength"   :show-password="item.password"  autocomplete="new-password"  :maxlength="item.maxlength"
                      :clearable="item.clearable || true" :readonly="item.readonly" :disabled="item.disabled"
                      :type="item.type || 'text'" :min="item.min" :max="item.max" :prefix-icon="item.prefixIcon"
                      :suffix-icon="item.suffixIcon" show-word-limit @change="controlChange"  @focus="focusSelect(item.isSelect,index)">
                      <template v-if="item.slot" :slot="item.slot">{{
                          item.slotText
                      }}</template>
                    </el-input>
                  </template>
                </el-form-item>
              </el-col>
            </template>
          </template>
          <slot name="append" />
        </el-row>
      </el-form>
      <el-row v-if="btns && btns.length > 0" :gutter="5" justify="center">
        <el-col class="colBtn">
          <template v-for="(btn, index) in btns">
            <el-button v-if="checkBtnPrivage(btn.code)" :key="index" :size="btn.size" :loading="btn.loading"
              :type="btn.type || 'primary'" :plain="btn.plain || false" :round="btn.round || false"
              :circle="btn.circle || false" :disabled="btn.disabled || false"
              @click="btnClick(btn.event, btn.isValidate)" v-preventReClick>
              <i v-if="btn.icon" :class="btn.icon" />
              <svg-icon v-if="btn.isSvgIcon" :icon-class="btn.icon" />
              {{ btn.label }}
            </el-button>
          </template>
        </el-col>
      </el-row>
    </div>
    <!-- </el-card> -->
  </div>
</template>
<script>
import { formProps } from './MyFormProps';
import TControl from '@/components/trina/base/TControl';
import TInput from '@/components/trina/base/input/TInput';
import TSelect from '@/components/trina/base/select/TSelect';
import TRadio from '@/components/trina/base/group/TRadio';
import TCheckbox from '@/components/trina/base/group/TCheckbox';
import TUploadPic from '@/components/trina/base/upload/TUploadPic';
import TColorInput from '@/components/trina/base/input/TColorInput';
import TTreeselect from '@/components/trina/base/select/TTreeSelect';
import Tinymce from '@/components/Tinymce';


export default {
  name: 'MyForm',
  components: {
    TInput,
    TSelect,
    TRadio,
    TCheckbox,
    TUploadPic,
    TColorInput,
    TTreeselect,
    Tinymce
  },
  extends: TControl,
  props: formProps,
  data() {
    const { items } = this.$props;
    const selectOptionPrefix = 'select-option-prefix';
    const dataObj = {
      selectOptions: {}
    };

    const deptId = this.$store.getters.deptId;

    const params = {};
    const format = {};

    items.forEach((v, i) => {
      if (
        (v.itemType === 't-checkbox' ||
          v.itemType === 'checkbox' ||
          // v.itemType === 'textarea' ||
          v.itemType === 'checkboxGroup') &&
        !v.defaultValue
      ) {
        v.defaultValue = [];
      }
      const propType = typeof v.prop;
      if (propType === 'string') {
        params[v.prop] = v.defaultValue;

        if (v.format) {
          format[v.prop] = v.format;
        }
      } else if (
        propType === 'object' &&
        Object.prototype.toString.call(v.prop) === '[object Array]'
      ) {
        v.prop.forEach(vv => {
          params[vv] = '';
          if (v.format) {
            format[vv] = v.format;
          }
        });
      }

      if (v.itemType === 'select' && (v.selectFetch || v.selectUrl)) {
        const dataKey = selectOptionPrefix + i;
        dataObj.selectOptions[dataKey] = [];
        const { $axios } = this;
        if (!v.selectMethod) {
          v.selectMethod = 'get';
        }
        this.getRemoteData({
          fetch: v.selectFetch
            ? v.selectFetch
            : () => {
              return $axios[v.selectMethod](
                v.selectUrl,
                v.selectMethod.toLowerCase() === 'get'
                  ? { params: v.selectParams }
                  : v.selectParams
              );
            },
          dataKey,
          resultField: v.selectResultField || 'result',
          resultHandler: v.selectResultHandler
        });
      }

      // if(v.itemType == 't-file'){
      //   getFileListById(1).then(res => {
      //     // if(res.code == 200){
      //     //   this.fileList = res.data;
      //     //   self.showDialog = true;
      //     // }
      //     v.fileList =
      //       [
      //         {
      //           "contextType":"image/png",
      //           "fileUrl":"http://dev.xiyoubike.cn/mini/api/v1/file/download?id=3859837386172096"
      //         },
      //         {
      //           "contextType":"image/png",
      //           "fileUrl":"http://dev.xiyoubike.cn/mini/api/v1/file/download?id=3859837386172096"
      //         },{
      //         "contextType":"video/mp4",
      //         "fileUrl":"http://dev.xiyoubike.cn/mini/api/v1/file/download?id=3859853382280896"
      //       },
      //       ];
      //   })
      //
      // }
    });
   console.log(params, 'params!!!!!!!');
    return {
      params,
      deptId,
      currentType: 1,
    };
  },
  created() {
    // this.updateParams()

  },
  mounted() {
    this.$refs.input.map(item =>{
      item.focus()
      item.select();
    })
  },
  computed: {
    span() {
      console.log(this.params.language_value, 'params-----')
      return 24 / this.colNum;
    },
    itemStyle() {
      const { itemWidth } = this;
      if (itemWidth) {
        return `width: ${itemWidth}px;`;
      }
      return '';
    },

  },
  watch: {
    params(val, oldVal) {
      Object.keys(val).forEach(function (key) {
        const value = val[key];
        if (Object.prototype.toString.call(value) === '[object Number]') {
          val[key] = value + '';
        }
      });
    }
  },
  methods: {
    focusSelect(flag){
      if(flag){
        console.log( this.$refs.input, '辖区信息');
        this.$refs.input.map(item =>{
          item.select();
        })
      }
    },
    checkBtnPrivage(code) {
      return this.checkPrivage(code);
    },
    getRemoteData({ fetch, dataKey, resultField, resultHandler }) {
      fetch().then(response => {
        let result = response;
        console.log(response, 'qqqqqqqqqqqqqqqqqqqqqqqqssssss');
        if (typeof response === 'object' && !this.isArray(response)) {
          if (resultField.indexOf('.') !== -1) {
            resultField.split('.').forEach(vv => {
              result = result[vv];
            });
          } else {
            result = response[resultField];
          }
        }

        if (!result || !(result instanceof Array)) {
          console.warn(
            `The result of key:${resultField} is not Array. 接口返回的字段:${resultField} 不是一个数组`
          );
        }

        if (this.resultHandler) {
          this.selectOptions[dataKey] = result.map(this.resultHandler);
        } else {
          this.selectOptions[dataKey] = result;
        }
      });
    },
    btnClick(event, isValidate) {
      const param = JSON.parse(JSON.stringify(this.params));
      if (isValidate) {
        this.$refs['myForm'].validate(valid => {
          if (valid) {
            this.emitEventHandler('btn-click', event, param);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.emitEventHandler('btn-click', event, param);
      }
    },
    emitEventHandler(event) {
      this.$emit(event, ...Array.from(arguments).slice(1));
    },
    resetFields() {
      this.$refs['myForm'].resetFields();
    },
    clearValidate() {
      this.$refs['myForm'].clearValidate();
    },
    getRadioValue() {

    },
    jurisdictionsFun(allJurisdictions, allJurisdictionsId) {
      if (allJurisdictions == undefined) {
        return;
      }
      if (allJurisdictions.length == 0) {
        allJurisdictionsId = sessionStorage.getItem("deptIds").split(",")
        return;
        // this.getValueDataList(allJurisdictions)
      }
      for (let item of allJurisdictions) {
        allJurisdictionsId.push(item.id)
        if (item.children) {
          this.jurisdictionsFun(item.children, allJurisdictionsId)
        }
      }
    },
    tGroupChange(modelName, modelValue, selectObj) {
      console.log('tGroupChange', modelName, modelValue, selectObj);
      if(localStorage.getItem('linkage')){
        this.params.label_value= modelValue
         console.log(this.params,'PROPS');

      }
      if (selectObj) {
        this.$emit('tGroupChange', selectObj);
      }
      if (modelName === 'type' || modelName === 'ctype') {
        this.$emit('radioValueChange', modelValue);
        if (modelValue == 2) {
          this.$forceUpdate()
        }
        this.$forceUpdate()
        // this.resetFields();
        const childrenItem = this.$refs['sysDeptId'][0];
        // childrenItem.clear();
        this.params.sysDeptId = null;
      }

      if (modelName === 'category') {
        const ref = this.$refs['area'][0];
        console.log('area', ref);
        ref.setParams({
          sysDeptId: this.deptId,
          category: modelValue
        })
        ref.getValueDataList();
      }

      this.params[modelName] = modelValue;
      this.controlChange(modelName, modelValue, selectObj);
      this.items.forEach((v, i) => {
        // console.log(v.parentItem, v.prop, i);
        console.log(v.prop);
        if(v.prop == 'form_id' && modelName == 'sys_dept_id'){
          console.log(v,'look')
          v.params.deptId = modelValue
          setTimeout( ()=> {
            this.$refs[v.prop][0].getValueDataList();
            this.$refs[v.prop][0].clear();
          },500)
          console.log(this.$refs[v.prop][0],'look2')
        }
        if(modelName == 'sysDeptId'){
          if(v.prop == 'skillIds' || v.prop == 'equipmentIds' || v.prop == 'bikeCategoryId'||v.prop =='userGrade'){
            v.params.deptId = modelValue
            setTimeout( ()=> {
              this.$refs[v.prop][0].getValueDataList();
              this.$refs[v.prop][0].clear();
            },500)
          }
        }
        if (v.parentItem === modelName && this.$refs[v.prop] && this.$refs[v.prop][0]) {
          const childrenItem = this.$refs[v.prop][0];
          // console.log(childrenItem);
          const params = {
            [modelName]: modelValue
          };
          if (modelName == 'deptId' || modelName == 'sysDeptId') {
            // 当选择辖区时赋值
            params.deptIds = [];
            this.jurisdictionsFun(selectObj.children, params.deptIds);
            params.deptIds.push(modelValue);
            params.deptId = '';
            params.sysDeptId = modelValue;
            this.deptId = modelValue;
            this.$emit('sysDeptIdChange', modelValue)
          }
          if(v.label == "辖区组长" && modelName == 'sysDeptId'){
            this.$refs[v.prop][0].clear();
          }

          // 是否需要控制显示
          if (v.isControlDisplay) {
            // 判断是否等于控制显示的值
            if (modelValue == v.displayModeValue) {
              if (v.displayMode == 1) {
                //Disabled
                childrenItem.disabled = true;
              } else if (v.displayMode == 2) {
                // hidden
                let formItem = childrenItem.$parent;
                console.log(formItem);
              }
            } else {
              if (v.displayMode == 1) {
                // Disabled
                childrenItem.disabled = false;
              } else if (v.displayMode == 2) {
                // hidden
              }
            }
          }
          if (childrenItem.setParams) {
            console.log('children:', childrenItem)
            childrenItem.setParams(params);
            childrenItem.getOptions();
          }
        }
      });
    },
    controlChange(modelName, modelValue, selectObj) {
      console.log('controlChange:', modelName, modelValue, selectObj)

      this.$emit('change_' + modelName, modelName, modelValue, selectObj);
      this.$emit('changeParentId' , modelName, modelValue, selectObj);
    },
    delData(prop,index){
      console.log(this.params[prop])
      const alertList = this.params[prop].split(';')
      alertList.splice(index,1)
      this.params[prop] = alertList.join(';')
    },
    updateParams(prop, preprop) {
      if(this.params[preprop]){
        if (this.params[prop]) {
          this.params[prop] += this.params[preprop] + ';'
        } else {
          this.params[prop] = this.params[preprop] + ';'
        }

      }
      this.params[preprop] = ''

    },

  }
};
</script>
<style scoped lang="scss">
/deep/.el-textarea .el-input__count {
      background-color: transparent;
      bottom: -29px;
  }

/deep/.el-alert{
  height: 30px;
}
.colBtn {
  text-align: center;
}

.el-select {
  width: 100%;
}

.width100 {
  width: 100%;
}

.san{
  cursor: pointer;
}

.alert{
  margin: 0;
  padding: 0;
  .san{
    font-size: 1.4vh;
    float:right;
  }
}

.alert:not(:first-child){
  margin-top:1vh
}

.collapse {
  opacity: 0;
  animation: tag-fade-in 0.8s ease-out forwards;
}
@keyframes tag-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
