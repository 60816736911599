var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("button", { on: { mousedown: _vm.startTalk, mouseup: _vm.stopTalk } }, [
      _vm._v("Press and Talk"),
    ]),
    _c("audio", { ref: "audioPlayer", attrs: { controls: "" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }