<template>
  <div>
    <button @mousedown="startTalk" @mouseup="stopTalk">Press and Talk</button>
    <audio ref="audioPlayer" controls></audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recorder: null,
      source: null,
      audioInput: null,
      context: null,
      audioVolume: null,
      index: 0,
      socket: null,
      arrBuffer: new ArrayBuffer(320 * 2),
      sendBuffer: null,
      sendBufferIndex: 0,
      pttId: 'your-ptt-id', // Replace with your actual PTT ID
      loginInfo: {
        uid: 'your-user-id', // Replace with your actual user ID
      },
      audioChunks: [],
    };
  },
  mounted() {
    this.context = new AudioContext();
    this.audioVolume = this.context.createGain();
    this.sendBuffer = new DataView(this.arrBuffer);
    this.initTalk();
    this.initWebSocket();
  },
  methods: {
    initTalk() {
      let that = this;
      navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
        that.audioInput = that.context.createMediaStreamSource(stream);
        that.recorder = that.context.createScriptProcessor(1024, 1, 1);
        that.recorder.onaudioprocess = function (e) {
          let buffer = e.inputBuffer.getChannelData(0);
          let arrayBuffer = that.context.createBuffer(1, 1024, that.context.sampleRate);
          let nowBuffering = arrayBuffer.getChannelData(0);
          for (let i = 0; i < arrayBuffer.length; i++) {
            nowBuffering[i] = buffer[i];
          }
          let offctx = new OfflineAudioContext(1, parseInt(1024 * 16000 / that.context.sampleRate), 16000);
          that.source = offctx.createBufferSource();
          that.source.buffer = arrayBuffer;
          that.source.connect(offctx.destination);
          that.source.start();
          offctx.startRendering().then(function (renderedBuffer) {
            let channetData = renderedBuffer.getChannelData(0);
            that.source.stop();
            let index = 0;
            let length = channetData.length;
            while (index < length) {
              let selectBuffer = channetData[index];
              let s = Math.max(-1, Math.min(1, selectBuffer));
              let point = s < 0 ? s * 0x8000 : s * 0x7FFF;
              if (that.sendBufferIndex < 320 * 2) {
                that.sendBuffer.setInt16(that.sendBufferIndex, point, true);
              } else {
                ++that.index;
                that.workerMessage(new Int8Array(that.arrBuffer), that.index);
                that.sendBuffer = new DataView(that.arrBuffer);
                that.sendBufferIndex = 0;
                that.sendBuffer.setInt16(that.sendBufferIndex, point, true);
              }
              index++;
              that.sendBufferIndex += 2;
            }
          });
        };
      }).catch(e => {
        console.log("e: ", e);
      });
    },
    startTalk() {
      this.audioInput?.connect(this.audioVolume);
      this.audioVolume?.connect(this.recorder);
      this.recorder?.connect(this.context.destination);
    },
    stopTalk() {
      this.recorder?.disconnect();
    },
    concatenate(view, offset, arrays) {
      for (let i = 0; i < arrays.byteLength; i++) {
        view.setInt8(offset + i, arrays[i]);
      }
      return view;
    },
    writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    workerMessage(buffer, index) {
      let tgId = 'your-group-id'; // Replace with your actual group ID
      let pttId = this.pttId;
      let Buffer = new ArrayBuffer(26 + pttId.length + buffer.length);
      let newBuffer = new DataView(Buffer);
      newBuffer.setInt32(0, 56); //消息类型
      newBuffer.setInt8(4, 1); //版本号
      newBuffer.setInt32(5, parseInt(tgId)); //对讲组id
      newBuffer.setInt32(9, parseInt(this.loginInfo.uid)); //语音发送者的id
      newBuffer.setInt32(13, pttId.length); //pttid长度
      this.writeString(newBuffer, 17, pttId); //pttid二进制数据
      newBuffer.setInt8(17 + pttId.length, 1); //编码方式
      newBuffer.setInt32(18 + pttId.length, index); //语音发送的序号
      newBuffer.setInt32(22 + pttId.length, buffer.length); //语音数据的长度
      this.concatenate(newBuffer, 26 + pttId.length, buffer);
      this.socket.send(newBuffer.buffer);
    },
    initWebSocket() {
      this.socket = new WebSocket('wss://your-websocket-url'); // Replace with your WebSocket URL
      this.socket.binaryType = 'blob';

      this.socket.onmessage = (event) => {
        this.handleReceivedMessage(event.data);
      };

      this.socket.onopen = () => {
        console.log('WebSocket connection opened');
      };

      this.socket.onclose = () => {
        console.log('WebSocket connection closed');
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error: ', error);
      };
    },
    handleReceivedMessage(blob) {
      this.audioChunks.push(blob);
      this.playReceivedAudio();
    },
    playReceivedAudio() {
      if (!this.$refs.audioPlayer.paused) {
        // If the audio is playing, just return
        return;
      }

      const audioPlayer = this.$refs.audioPlayer;
      const blob = new Blob(this.audioChunks, { type: 'audio/ogg; codecs=opus' });
      const url = URL.createObjectURL(blob);

      audioPlayer.src = url;
      audioPlayer.play().then(() => {
        // Clear the chunks once the audio is played
        this.audioChunks = [];
      }).catch((error) => {
        console.error('Error playing audio:', error);
      });
    },
  },
};
</script>

<style scoped>
button {
  padding: 10px 20px;
  font-size: 16px;
}
audio {
  display: none;
}
</style>
