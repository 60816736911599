var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._m(0),
      _c("el-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        on: { click: _vm.aplayAudio },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.notifyDialog3,
            title: "智慧助手",
            "append-to-body": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.notifyDialog3 = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("span", [_vm._v(_vm._s(this.msgContent))]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "text-align": "center",
                "margin-top": "20px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRowClick3()
                    },
                  },
                },
                [_vm._v("查看详情 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.notifyDialog4,
            title: "表单管理",
            "append-to-body": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.notifyDialog4 = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("span", [
              _vm._v(_vm._s("【" + this.msgTitle + "】" + this.msgContent)),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "text-align": "center",
                "margin-top": "20px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRowClick4()
                    },
                  },
                },
                [_vm._v("查看详情 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.notifyDialog,
            title: "告警通知",
            "append-to-body": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.notifyDialog = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("span", [_vm._v(_vm._s(this.msgContent))]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "text-align": "center",
                "margin-top": "20px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRowClick()
                    },
                  },
                },
                [_vm._v("查看详情 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.notifyDialogv2,
            title: "纠纷通知",
            "append-to-body": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.notifyDialogv2 = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("span", [_vm._v(_vm._s(this.msgContentv2))]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "text-align": "center",
                "margin-top": "20px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRowClickV2(2)
                    },
                  },
                },
                [_vm._v("查看详情 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.showPbx,
            modal: false,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "custom-class": "customClass",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPbx = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "videogroup",
              style: { height: _vm.contentHeight - 110 - _vm.isHeight + "px" },
            },
            [
              _vm.sipState == 0
                ? _c("div", {
                    staticStyle: {
                      "text-align": "center",
                      color: "#00000066",
                      "font-size": "18px",
                    },
                  })
                : _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.sipState == 0,
                              expression: "sipState == 0",
                            },
                          ],
                        },
                        [_c("div")]
                      ),
                      _c(
                        "div",
                        {
                          ref: "player",
                          staticClass: "player",
                          staticStyle: { width: "100%", height: "100%" },
                          on: {
                            mouseenter: _vm.showElement,
                            mouseleave: _vm.hideElement,
                          },
                        },
                        [
                          _vm.isElementVisible
                            ? _c(
                                "div",
                                {
                                  staticClass: "full-screen",
                                  on: { click: _vm.fullScreenFun },
                                },
                                [
                                  !_vm.iFullscreen
                                    ? _c("img", {
                                        attrs: {
                                          src: require("@/assets/音视频全屏.png"),
                                          alt: "",
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("@/assets/音视频全屏收缩.png"),
                                          alt: "",
                                        },
                                      }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.sipState == 1,
                                  expression: "sipState == 1",
                                },
                              ],
                              staticClass: "Calling",
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.title) +
                                    "呼叫'" +
                                    _vm._s(_vm.titleName) +
                                    "'中..."
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  staticClass: "stopButton",
                                  attrs: { type: "danger" },
                                  on: { click: _vm.handleCancelCall },
                                },
                                [_vm._v("挂断 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.sipState == 2,
                                  expression: "sipState == 2",
                                },
                              ],
                              staticClass: "videoSuccess",
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.title == "视频",
                                      expression: "title == '视频'",
                                    },
                                  ],
                                  staticClass: "video",
                                },
                                [
                                  _c("div", { attrs: { id: "videoRemote" } }),
                                  _c("div", { staticClass: "video-local" }, [
                                    _c("div", {
                                      attrs: {
                                        id: "videoLocal",
                                        width: "100%",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "点击切换语音通话",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "toggle-aduio",
                                        attrs: {
                                          title: "点击切换语音通话",
                                          src: require("@/assets/images/toggleAudio.png"),
                                          fit: "fill",
                                        },
                                        on: { click: _vm.requestVideo2Audio },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.title == "语音",
                                      expression: "title == '语音'",
                                    },
                                  ],
                                  staticClass: "audio",
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "120px",
                                      height: "120px",
                                      "margin-bottom": "20px",
                                    },
                                    attrs: { src: _vm.audioUrl, fit: "fill" },
                                  }),
                                  _c("div", { staticClass: "demonstration" }, [
                                    _vm._v(
                                      "正在与'" +
                                        _vm._s(_vm.titleName) +
                                        "'通话中"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "button" },
                                [
                                  _c("div", { staticClass: "time" }, [
                                    _vm._v(_vm._s(_vm.str || "00:00")),
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "stopButton",
                                      attrs: { type: "danger" },
                                      on: { click: _vm.hangupClick },
                                    },
                                    [_vm._v("挂断 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.IntercomnotifyDialogVisible,
            "custom-class": "customClass",
            modal: false,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.IntercomnotifyDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "IntercomnotifyDialog" }, [
            _c("h3", { staticStyle: { "text-align": "left" } }, [
              _vm._v(_vm._s(_vm.zhzfData.userName)),
            ]),
            _c("h2", { staticStyle: { color: "white" } }, [
              _vm._v(_vm._s(_vm.interphoneStateText)),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [
                _c("el-image", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.isRecording &&
                        _vm.interphoneStateText !== "对方正在讲话...",
                      expression:
                        "isRecording && interphoneStateText !== '对方正在讲话...'",
                    },
                  ],
                  staticClass: "audioImge",
                  staticStyle: { width: "80px", height: "80px" },
                  attrs: { src: _vm.audioUrl, fit: "fill" },
                }),
                _vm.interphoneStateText == "对方正在讲话..."
                  ? _c("div", { staticClass: "userAudio" }, [
                      _c("span"),
                      _c("span"),
                      _c("span"),
                      _c("span"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  { attrs: { type: "danger" }, on: { click: _vm.endTalk } },
                  [_vm._v("挂断对讲")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("audio", { attrs: { id: "audio", preload: "auto" } }, [
      _c("source", {
        attrs: { src: "https://images.cyoubike.com/notify.wav" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }