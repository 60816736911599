import request from '@/utils/request';
import { type } from 'jquery';

//获取所有资源信息
export function resourceTreeAll(data) {
  return request({
    url: '/web/system/resource/resourceTreeAll',
    method: 'post',
    data,
  });
}

// 保存排序后的菜单
export function updateDataIndex(data) {
  return request({
    url: '/web/system/resource/updateDataIndex',
    method: 'post',
    data,
  });
}

// 恢复默认排序
export function defaultDataIndex(data) {
  return request({
    url: '/web/system/resource/defaultDataIndex',
    method: 'post',
    data,
  });
}

// 获取当前用户的资源信息
export function resourceTree() {
  return request({
    url: '/web/system/resource/resourceTree',
    method: 'get',
  });
}

// 根据角色获取资源Id
export function getResourceByRole(roleId) {
  return request({
    url: '/web/system/resource/getResourceByRole/' + roleId,
    method: 'get',
  });
}

// 保存角色资源关系
export function saveRoleResource(data) {
  return request({
    url: '/web/system/resource/saveRoleResource',
    method: 'post',
    data,
  });
}

// 保存更新通知
export function pingSave(data) {
  return request({
    url: '/web/ping/save',
    method: 'post',
    data,
  });
}

// 修改更新通知
export function updatePingServe(data) {
  return request({
    url: '/web/ping/updatePingServe',
    method: 'post',
    data,
  });
}
// 删除更新通知
export function pingDelete(id) {
  return request({
    url: '/web/ping/delete/' + id,
    method: 'get',
  });
}
// 批量删除更新通知
export function pingServerMany(data) {
  return request({
    url: '/web/remove/pingServerMany',
    method: 'post',
    data,
  });
}

export function searchDeptByNameOrId(data) {
  return request({
    url: 'app/v1/dept/searchDeptByNameOrId',
    method: 'post',
    data,
  });
}

// 智慧助手通知消息列表
export function getSendTaskMsg(data) {
  return request({
    url: '/web/smart/getSendTaskMsg',
    method: 'post',
    data,
  });
}


export function listAllPolice(data) {
  return request({
    url: '/web/sysMsg/listAllPolice',
    method: 'post',
    data,
  });
}


export function MessagePresence(data) {
  return request({
    url: '/web/sysMsg/MessagePresence',
    method: 'post',
    data,
  });
}

export function deleteTaskDatails(id) {
  return request({
    url: '/web/smart/deleteTaskDatails/' + id ,
    method: 'post',
  });
}


// 删除模版
export function deleteModels(data) {
  return request({
    url: '/web/smart/deleteModels',
    method: 'post',
    data
  });
}





// 删除控件需调用的接口
export function whetherApplyControl(id) {
  return request({
    url: '/web/smart/whetherApplyControl/' + id ,
    method: 'get',
  });
}

// 删除控件选项需调用的接口
export function whetherApplyControlOptions(id) {
  return request({
    url: '/web/smart/whetherApplyControlOptions/' + id ,
    method: 'get',
  });
}





export function smartMsgCount(userId) {
  return request({
    url: '/web/smart/smartMsgCount/' + userId,
    method: 'get',
  });
}


export function smartBashRead(data) {
  return request({
    url: '/web/smart/smartBashRead',
    method: 'post',
    data,
  });
}

//保存字典
export function saveCoreValsetDicts(data) {
  return request({
    url: '/web/coreValsetDicts/saveCoreValsetDicts',
    method: 'post',
    data,
  });
}
//修改字典
export function updateCoreValsetDicts(data) {
  return request({
    url: '/web/coreValsetDicts/updateCoreValsetDicts',
    method: 'post',
    data,
  });
}
// 发任务通知未读数量
export function pliceMsgCount(userId) {
  return request({
    url: '/web/sysMsg/pliceMsgCount/' + userId,
    method: 'get',
  });
}
// 发任务通知读取
export function readMsgCounts(data) {
  return request({
    url: '/web/sysMsg/readMsgCounts',
    method: 'post',
    data,
  });
}

// 有情报通知消息列表
export function listAllPopulation(data) {
  return request({
    url: '/web/sysMsg/listAllPopulation',
    method: 'post',
    data,
  });
}
// 有情报通知未读数量
export function populationMsgCount(userId) {
  return request({
    url: '/web/sysMsg/populationMsgCount/' + userId,
    method: 'get',
  });
}
//有情报通知读取
export function readMsgCountPopulations(data) {
  return request({
    url: '/web/sysMsg/readMsgCountPopulations',
    method: 'post',
    data,
  });
}

// 告警通知消息列表
export function listAllAlarm(data) {
  return request({
    url: '/web/sysMsg/listAllAlarm',
    method: 'post',
    data,
  });
}
// 告警通知未读数量
export function alarmMsgCount(userId) {
  return request({
    url: '/web/sysMsg/alarmMsgCount/' + userId,
    method: 'get',
  });
}
//告警通知读取
export function readMsgCountAlarms(data) {
  return request({
    url: '/web/sysMsg/readMsgCountAlarms',
    method: 'post',
    data,
  });
}
//告警通知读取
export function alarmMsgExportExcel(data) {
  return request({
    url: '/web/sysMsg/alarmMsgExportExcel',
    method: 'POST',
    responseType: 'blob',
    data: data,
  });
}
//告警通知读取
export function bathDelete(data) {
  return request({
    url: '/web/sysMsg/bathDelete',
    method: 'POST',
    data: data
  });
}
export function userExcelV2(data) {
  return request({
    url: '/web/system/user/userExcelV2' ,
    method: 'post',
    data: data,
    responseType: 'blob'
  });
}
// 审核注册消息列表
export function listAllApprove(data) {
  return request({
    url: '/web/sysMsg/listAllApprove',
    method: 'post',
    data,
  });
}
// 审核注册未读数量
export function registerMsgCount(deptID) {
  return request({
    url: '/web/sysMsg/registerMsgCount/' + deptID,
    method: 'get',
  });
}
// 审核注册审批
export function approveRegister(data) {
  return request({
    url: '/app/v1/user/approveRegister',
    method: 'post',
    data,
  });
}

// 更新通知消息列表
export function getUnReadPingServeV2(data) {
  return request({
    url: '/web/sysMsg/getUnReadPingServeV2',
    method: 'post',
    data,
  });
}
// 更新通知未读数量
export function getUnReadPingCount() {
  return request({
    url: '/web/sysMsg/getUnReadPingCount',
    method: 'post',
  });
}
// 更新通知读取
export function getUnReadPings(data) {
  return request({
    url: '/web/sysMsg/getUnReadPings',
    method: 'post',
    data,
  });
}

// 纠纷调处消息列表
export function getDisputeMsgServeV2(data) {
  return request({
    url: '/web/sysMsg/getDisputeMsgServeV2',
    method: 'post',
    data,
  });
}
// 纠纷调处未读数量
export function getDisputeMsgCount() {
  return request({
    url: '/web/sysMsg/getDisputeMsgCount',
    method: 'post',
  });
}
// 纠纷调处读取
export function getDisputeMsgUnReadPings(data) {
  return request({
    url: '/web/sysMsg/getDisputeMsgUnReadPings',
    method: 'post',
    data,
  });
}

// 告警管理列表
export function getAlarmRulesConfigListAll(data) {
  return request({
    url: '/web/sysMsg/getAlarmRulesConfigListAll',
    method: 'post',
    data,
  });
}
// 删除告警管理
export function deleteByIds(data) {
  return request({
    url: '/web/alarm/deleteByIds',
    method: 'post',
    data,
  });
}
// 修改告警管理
export function updateAlarm(data) {
  return request({
    url: '/web/alarm/updateAlarm',
    method: 'post',
    data,
  });
}
// 新增告警管理
export function saveAlarm(data) {
  return request({
    url: '/web/alarm/saveAlarm',
    method: 'post',
    data,
  });
}
// 获取告警车辆与人员
export function findPersonOrVehicleByDepartmentAndCategory(data) {
  return request({
    url:
      '/web/alarm/findPersonOrVehicleByDepartmentAndCategory/' +
      data.deptId +
      '/' +
      data.categoryId,
    method: 'get',
  });
}

// 告警配置详情
export function selectById(id) {
  return request({
    url: '/web/alarm/selectById/' + id,
    method: 'get',
  });
}

export function getMessageMenuAlias() {
  return request({
    url: '/web/system/resource/getMessageMenuAlias',
    method: 'get',
  });
}
export function notificationShowDeptList(id) {
  return request({
    url: '/web/merchant/notificationShowDeptList?deptId=' + id,
    method: 'get',
  });
}

// export function getMessageMenuAlias() {
//   return request({
//     url: '/web/system/resource/getMessageMenuAlias',
//     method: 'get',
//   });
// }

// 开关批量修改
export function updateBachUserDevicer(data) {
  return request({
    url: '/web/dept/updateBachUserDevicer',
    method: 'post',
    data,
  });
}

// 开关单个修改
export function updateUserDevicer(data) {
  return request({
    url: '/web/dept/updateUserDevicer',
    method: 'post',
    data,
  });
}

// 总览管理内容
export function getOverviewContentV3(id) {
  return request({
    url: '/web/v2/dashboardV3/getOverviewContentV3?deptId=' + id,
    method: 'get',
  });
}

// 总览内容--编辑操作
export function overviewContentEditV3(data) {
  return request({
    url: '/web/v2/dashboardV3/overviewContentEditV3',
    method: 'post',
    data,
  });
}

// 总览地图操作
export function getOverviewMapHandleV3(id) {
  return request({
    url: '/web/v2/dashboardV3/getOverviewMapHandleV3?deptId=' + id,
    method: 'get',
  });
}

// 总览地图内容--编辑操作
export function getOverviewMapContentV3(id) {
  return request({
    url: '/web/v2/dashboardV3/getOverviewMapContentV3?deptId=' + id,
    method: 'get',
  });
}
//数据刷新时间
export function getOverviewReamTimeV3(id) {
  return request({
    url: '/web/v2/dashboardV3/getOverviewReamTimeV3?deptId=' + id,
    method: 'get',
  });
}

// 总览样式标题--编辑操作
export function updateDeptConfig(data) {
  return request({
    url: '/web/deptConfig/updateDeptConfig',
    method: 'post',
    data,
  });
}

// 总览地图功能操作--编辑操作
export function overviewMapHandleEditV3(data) {
  return request({
    url: '/web/v2/dashboardV3/overviewMapHandleEditV3',
    method: 'post',
    data,
  });
}

// 总览管理渲染-地图内容--编辑操作
export function overviewMapContentV3(data) {
  return request({
    url: '/web/v2/dashboardV3/overviewMapContentV3',
    method: 'post',
    data,
  });
}

// 修改数据刷新时间
export function overviewReamTimeV3(data) {
  return request({
    url: '/web/v2/dashboardV3/overviewReamTimeV3',
    method: 'post',
    data,
  });
}

// 地图按钮记忆存储
export function updateSelected(data) {
  return request({
    url: '/web/v2/dashboardV3/updateSelected',
    method: 'post',
    data,
  });
}

//数据刷新时间
export function findParams(id) {
  return request({
    url: '/web/v2/dashboardV3/findParams?deptId=' + id,
    method: 'get',
  });
}

// 总览配置复制
export function copyOverviewV3(data) {
  return request({
    url: '/web/v2/dashboardV3/copyOverviewV3',
    method: 'post',
    data,
  });
}

// 恢复默认配置
export function getOverviewV3(id) {
  return request({
    url: '/web/v2/dashboardV3/getOverviewV3?deptId=' + id,
    method: 'get',
  });
}

// 辖区排序
export function deptSortV3(data) {
  return request({
    url: '/web/v2/dashboardV3/deptSortV3',
    method: 'post',
    data,
  });
}

// 获取固定定位列表
export function getFixedPointList(data) {
  return request({
    url: '/web/bike/getFixedPointList',
    method: 'post',
    data,
  });
}
export function updateFrequency(data) {
  return request({
    url: '/app/v1/patrol/updateFrequency',
    method: 'post',
    data,
  });
}

// 导出固定点位
export function pointsListExport(data) {
  return request({
    url: '/web/pointScanAttendance/pointsListExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function pointScanAttendanceListExport(data) {
  return request({
    url: '/web/pointScanAttendance/pointScanAttendanceListExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function exportFixedPoint(data) {
  return request({
    url: '/web/bike/exportFixedPoint',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
export function exportModeLine(data) {
  return request({
    url: '/web//intell/model/line/exportModeLine',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

// 下载列表固定定位二维码
export function getFixedPointUrlList(data) {
  return request({
    url: '/web/bike/getFixedPointUrlList',
    method: 'post',
    data,
  });
}

// 批量修改固定点信息
export function pointsListBach(data) {
  return request({
    url: '/web/pointScanAttendance/pointsListBach',
    method: 'post',
    data,
  });
}

// 批量下载二维码
export function bachFixedPointUrlList(data) {
  return request({
    url: '/web/bike/bachFixedPointUrlList',
    method: 'post',
    data,
  });
}

// 修改签到规则
export function updateFixedOne(data) {
  return request({
    url: '/web/pointScanAttendance/updateFixedOne',
    method: 'post',
    data,
  });
}

// 获取固定点位扫码签到统计数据
export function getFixCntByDeptIds(data) {
  return request({
    url: '/web/pointScanAttendance/getFixCntByDeptIds',
    method: 'post',
    data,
  });
}

// 获取固定点位扫码签到统计数据
export function getCategoryCntByDeptIds(data) {
  return request({
    url: '/web/bike/getCategoryCntByDeptIds',
    method: 'post',
    data,
  });
}

// 获取扫码记录
export function pointScanAttendance(data) {
  return request({
    url: '/web/pointScanAttendance/pointScanAttendance',
    method: 'post',
    data,
  });
}

// 新增或修改考评模式
export function saveOrUpdateEvaluationMode(data) {
  return request({
    url: '/web/evaluation/saveOrUpdateEvaluationMode',
    method: 'post',
    data,
  });
}

// 新增或修改考评模式
export function queryEvaluationModeDetail(id) {
  return request({
    url: '/web/evaluation/queryEvaluationModeDetail?id=' + id,
    method: 'get',
  });
}

// 批量删除考评模式
export function bashDeleteEvaluationMode(data) {
  return request({
    url: '/web/evaluation/bashDeleteEvaluationMode',
    method: 'post',
    data,
  });
}

// ### 绩效总览导出
export function personalPerformanceExport(data) {
  return request({
    url: '/web/evaluation/personalPerformanceExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function patrolLogExport(data) {
  return request({
    url: '/web/system/personnel/patrolLogExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
export function exportSelectInstanceJobNew(data) {
  return request({
    url: '/web/intell/instance/job/exportSelectInstanceJobNew',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
export function userPointsExport(data) {
  return request({
    url: '/web/points/userPointsExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
export function ordersExport(data) {
  return request({
    url: '/web/goods/export',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
export function userPointsRecordExport(data) {
  return request({
    url: '/web/points/userPointsRecordExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
export function pointsRankExport(data) {
  return request({
    url: '/web/points/pointsRankExport',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
// ### 批量导出考评模式
export function evaluationModeExport(data) {
  return request({
    url: '/web/evaluation/evaluationModeExport',
    responseType: 'blob',
    method: 'post',
    data,
  });
}

export function exportCallPct(data) {
  return request({
    url: '/web/call/detail/exportCallPc',
    responseType: 'blob',
    method: 'post',
    data,
  });
}


// 查询个人绩效明细
export function queryPersonalPerformanceDetail(data) {
  return request({
    url: '/web/evaluation/queryPersonalPerformanceDetail',
    method: 'post',
    data,
  });
}

// 新增或修改项目（根据id来判断是新增还是修改）
export function saveOrUpdateEvaluationProjects(data) {
  return request({
    url: 'web/evaluation/saveOrUpdateEvaluationProjects',
    method: 'post',
    data,
  });
}


// 项目详情
export function queryEvaluationProjectsDetail(id) {
  return request({
    url: '/web/evaluation/queryEvaluationProjectsDetail?id=' + id,
    method: 'get',
  });
}


// 批量删除考评项目
export function bashDeleteEvaluationProjects(data) {
  return request({
    url: '/web/evaluation/bashDeleteEvaluationProjects',
    method: 'post',
    data,
  });
}

// ##### 新增或修改图标
export function addOrUpdateIconLibrary(data) {
  return request({
    url: '/web/icon/addOrUpdateIconLibrary',
    method: 'post',
    data,
  });
}
//新增或修改辖区类型
export function saveOrUpdateDeptType(data) {
  return request({
    url: '/web/deptType/saveOrUpdate',
    method: 'post',
    data,
  });
}

// 删除图标
export function deleteIconLibrary(data) {
  return request({
    url: '/web/icon/deleteIconLibrary',
    method: 'post',
    data,
  });
}
//删除辖区类型
export function bashDeleteDeptType(data) {
  return request({
    url: '/web/deptType/bashDelete',
    method: 'post',
    data,
  });
}

// 新增或修改撒点类型列表
export function saveOrUpdate(data) {
  return request({
    url: '/web/categoryV2/saveOrUpdate',
    method: 'post',
    data,
  });
}

// 新增或修改撒点类型列表
export function saveOrUpdateLabel(data) {
  return request({
    url: '/web/intellObjectCategoryLabel/saveOrUpdate',
    method: 'post',
    data,
  });
}

// 新增或修改人员类型
export function saveOrUpdatePersonnelType(data) {
  return request({
    url: '/web/personnelType/saveOrUpdate',
    method: 'post',
    data,
  });
}


// 查询撒点类型详情
export function getCategoryV2Detail(id) {
  return request({
    url: '/web/categoryV2/getCategoryV2Detail?id=' + id,
    method: 'get',
  });
}
// 查询人员类型详情
export function getSysPersonnelTypeDetail(id) {
  return request({
    url: '/web/personnelType/getSysPersonnelTypeDetail?id=' + id,
    method: 'get',
  });
}

export function getIntellObjectCategoryLabelDetail(id) {
  return request({
    url: '/web/intellObjectCategoryLabel/getIntellObjectCategoryLabelDetail?id=' + id,
    method: 'get',
  });
}
// 撒点类型批量修改
export function bashUpdate(data) {
  return request({
    url: '/web/categoryV2/bashUpdate',
    method: 'post',
    data,
  });
}

export function intellObjectbashUpdate(data) {
  return request({
    url: '/web/intellObjectCategoryLabel/bashUpdate',
    method: 'post',
    data,
  });
}

export function personnelTypeUpdate(data) {
  return request({
    url: '/web/personnelType/bashUpdate',
    method: 'post',
    data,
  });
}

// 批量删除撒点类型
export function bashDelete(data) {
  return request({
    url: '/web/categoryV2/bashDelete',
    method: 'post',
    data,
  });
}

export function copyDisputeConfigByDeptId(data) {
  return request({
    url: '/web/dispute/copyDisputeConfigByDeptId',
    method: 'post',
    data,
  });
}

// 多条件筛选获取用户
export function getUserList(data) {
  return request({
    url:`/web/system/user/getUserList`,
    method: 'post',
    data
  });
}










export function getCategorys(id,type) {
  let type1 = type

  return request({
    url: '/web/system/user/getCategorys/' + id +  '/' + type1,
    method: 'get',
  });
}


export function getUserGrades(id, type) {
  let type1 = type
  return request({
    url: '/web/system/user/getUserGrades/' + id + '/' + type1,
    method: 'get',
  });
}

export function getByUserNameList(name,deptId) {
  return request({
    url: '/web/system/user/getByUserNameList/' + name + '/' + deptId,
    method: 'get',
  });
}




// 智慧助手模板列表

export function smartModelListAll(data) {
  return request({
    url: '/web/smart/smartModelListAll',
    method: 'post',
    data,
  });
}




export function getTaskRemind() {
  return request({
    url: '/web/smart/getTaskRemind',
    method: 'get',
  });
}

// 保存模板全部信息
export function saveModelAllDetails(data) {
  return request({
    url: '/web/smart/saveModelAllDetails',
    method: 'post',
    data,
  });
}



export function finByModelId(id) {
  return request({
    url: '/web/smart/finByModelId/' + id,
    method: 'get',
  });
}



export function finByControlId(id) {
  return request({
    url: '/web/smart/finByControlId/' + id,
    method: 'get',
  });
}



export function getSmartRule(id) {
  return request({
    url: '/web/smart/getSmartRule/' + id,
    method: 'get',
  });
}


export function updateSmartModel(data) {
  return request({
    url: '/web/smart/updateSmartModel',
    method: 'post',
    data,
  });
}


export function updateSmartRule(data) {
  return request({
    url: '/web/smart/updateSmartRule',
    method: 'post',
    data,
  });
}

export function getSendTaskModel(id,type) {
  return request({
    url: '/web/smart/getSendTaskModel/' + id + '/' + type,
    method: 'get',
  });
}

export function sendTaskOrFeedbackTaskOrRemark(data) {
  return request({
    url: '/web/smart/sendTaskOrFeedbackTaskOrRemark',
    method: 'post',
    data,
  });
}


// 模版任务列表
export function selectTaskListAll(data) {
  return request({
    url: '/web/smart/selectTaskListAll',
    method: 'post',
    data,
  });
}


export function getTaskDatails(id) {
  return request({
    url: '/web/smart/getTaskDatails/' + id ,
    method: 'get',
  });
}

export function checkOrders(data) {
  return request({
    url: '/web/goods/checkOrders',
    method: 'post',
    data,
  });
}

